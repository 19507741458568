import { InteractionT } from 'shared/types/facesign'
import * as Sentry from '@sentry/react'
import get from 'lodash/get'
import PACKAGE from '../../package.json'
import jsLogger from 'js-logger'

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
}

const mimeToExtMap = {
  'video/mp4': 'mp4',
  'video/webm': 'webm',
  'video/ogg': 'ogg',
  'video/quicktime': 'mov',
  'video/x-matroska': 'mkv',
  'video/x-msvideo': 'avi'
}

export const sendCode = async (
  email: string,
  agreed: boolean,
  variant: InteractionT.VariantT,
  isTest: boolean,
  model: string | null,
  avatar: 'heygen' | 'azure'
) => {
  try {
    const windowUrl = new URL(window.location.href)
    const noVideo = windowUrl.searchParams.get('nv') !== null
    const url = `${process.env.REACT_APP_API_URL}/emailCode/send`
    const isDev = window.location.hostname.startsWith('code.int')
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        email,
        agreed,
        variant,
        isTest,
        version: PACKAGE.version,
        model,
        avatar,
        isDev,
        noVideo
      })
    })
    const res = await req.json()
    return res
  } catch (e) {
    jsLogger.warn('interactionStart error', e)
    // Sentry.captureException(e)
    return null
  }
}

export const conversationStart = async (
  verificationId: string,
  variant: InteractionT.VariantT,
  model: string | null,
  avatar: 'heygen' | 'azure'
) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/emailCode/start`
    // const pageUrl = new URL(window.location.href)
    const params = {
      verificationId,
      //comment out the param to send slack notifictions about local sessions
      slackNotificationsDisabled:
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1',
      variant,
      model,
      version: PACKAGE.version,
      avatar,
      isDev: window.location.host.startsWith('code.int')
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    jsLogger.log('conversationStart', params)
    const res = await req.json()
    jsLogger.log('conversationStart res', res)
    return res
  } catch (e) {
    jsLogger.error('interactionStart error', e)
    // Sentry.captureException(e)
    return
  }
}

export const submitReply = async (
  interactionId: string,
  reply: string,
  hgLatency: { qId: string; value: number },
  dgLatency: number
) => {
  try {
    const pageUrl = new URL(window.location.href)
    const variant = pageUrl.searchParams.get('variant')
    const url = `${process.env.REACT_APP_API_URL}/emailCode/submitReply`
    const params = {
      interactionId,
      reply,
      variant,
      hgLatency,
      dgLatency
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('submitReply res', res)
    return res
  } catch (e) {
    jsLogger.warn('submitReply error', e)
    // Sentry.captureException(e)
    return
  }
}

export const submitReply2 = async (interactionId: string, reply: string) => {
  try {
    const pageUrl = new URL(window.location.href)
    const variant = pageUrl.searchParams.get('variant')
    const url = `${process.env.REACT_APP_API_URL}/emailCode/generateResponse`
    const params = {
      interactionId,
      reply,
      variant
    }
    const startTime = Date.now()
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('create reply latency', Date.now() - startTime)
    jsLogger.log('submitReply res', res)
    return res
  } catch (e) {
    jsLogger.warn('submitReply error', e)
    // Sentry.captureException(e)
    return
  }
}

export const applyResponse = async (
  interactionId: string,
  q: InteractionT.QuestionT
) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/emailCode/applyResponse`
    const params = {
      interactionId,
      q
    }
    const req = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(params)
    })
    const res = await req.json()
    jsLogger.log('applyResponse res', res)
    return res
  } catch (e) {
    jsLogger.warn('applyResponse error', e)
    // Sentry.captureException(e)
    return
  }
}

export const submitUserVideo = async (
  interactionId: string,
  videoBlob: Blob,
  mimeType: string,
  duration: number
) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/emailCode/${interactionId}/submitUserVideo?mimeType=${encodeURIComponent(
    mimeType
  )}&duration=${duration}`

  const req = await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/octet-stream' },
    body: videoBlob
  })
  const res = await req.json()
  jsLogger.log('submitUserVideo res', res)
  return res
}

export const submitAvatarVideo = async (
  interactionId: string,
  videoBlob: Blob,
  mimeType: string,
  duration: number
) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/emailCode/${interactionId}/submitAvatarVideo?mimeType=${encodeURIComponent(
    mimeType
  )}&duration=${duration}`

  const req = await fetch(url, {
    method: 'POST',
    headers: { ...headers, 'Content-Type': 'application/octet-stream' },
    body: videoBlob
  })
  const res = await req.json()
  jsLogger.log('submitAvatarVideo res', res)
  return res
}

export const getRecapInfo = async (interactionId: string) => {
  const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/recap`
  const req = await fetch(url, {
    method: 'GET',
    headers
  })
  if (req.ok) {
    const res = (await req.json()) as InteractionT.RecapT
    jsLogger.log('getRecapInfo res', res)
    return res
  } else {
    jsLogger.warn('getRecapInfo error', req.statusText)
    return null
  }
}

export const sendUserScreenshot = async (
  interactionId: string,
  screenshotBlob: Blob
) => {
  try {
    jsLogger.log('sendUserScreenshot', screenshotBlob.size)
    const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/screenshot`
    const req = await fetch(url, {
      method: 'POST',
      body: screenshotBlob,
      headers: { ...headers, 'Content-Type': 'application/octet-stream' }
    })
    const data = await req.json()

    jsLogger.log('sendUserScreenshot response', data)
    return data.success
  } catch (e) {
    jsLogger.error('could not sent screenshot', { error: e })
    return false
  }
}

export const applyTestScreenshots = async (interactionId: string) => {
  try {
    jsLogger.log('applyTestScreenshots')
    const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/testScreenshot`
    const req = await fetch(url, {
      method: 'POST',
      headers
    })
    const data = await req.json()

    jsLogger.log('applyTestScreenshots response', data)
    return data.success
  } catch (e) {
    jsLogger.error('applyTestScreenshots error', { error: e })
    return false
  }
}

export const generateId = (): string => {
  const currentDate = new Date()
  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')
  const seconds = currentDate.getSeconds().toString().padStart(2, '0')
  const milliseconds = currentDate.getMilliseconds().toString().padStart(3, '0')
  const id = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`
  return id
}

export const uploadBlobToStorage = async (
  blob: Blob,
  storagePath: string
): Promise<void> => {
  const extension = get(mimeToExtMap, blob.type.split(';')[0]) || 'webm'
  const storageEndpoint = `https://firebasestorage.googleapis.com/v0/b/${
    process.env.REACT_APP_FIREBASE_STORAGE_ID
  }/o/${encodeURIComponent(storagePath)}.${extension}`
  try {
    jsLogger.log('Blob details:', {
      type: blob.type,
      size: blob.size,
      extension
    })
    const responseBlob = await fetch(storageEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': blob.type
      },
      body: blob
    })

    if (!responseBlob.ok) {
      throw new Error(
        `Failed to upload Blob to storage: ${responseBlob.statusText}`
      )
    }

    jsLogger.log('Blob uploaded successfully')
  } catch (error) {
    jsLogger.error('Error uploading Blob to storage:', error)
  }
}

export const sendError = async (
  event: Sentry.Event,
  hint: Sentry.EventHint
) => {
  const url = `${process.env.REACT_APP_API_URL}/emailCode/error`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ event, hint }),
    headers
  })
  const data = await req.json()

  jsLogger.log('sendUserScreenshot response', data)
  return data
}

export const setChunksCompleted = async (
  interactionId: string,
  duration: number
) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/chunks-completed`
    await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({ duration })
    })
  } catch (e) {
    jsLogger.error('setChunksCompleted error', e)
  }
}

// let logsSentAt = Date.now()

// let logsBuffer: InteractionT.LogT[] = []

// export const logEvent = async (
//   interactionId: string,
//   logType: InteractionT.LogTypeT,
//   data?: object,
//   flush: boolean = false
// ) => {
//   try {
//     const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/log`
//     const l: InteractionT.LogT = {
//       timestamp: Date.now(),
//       type: logType,
//       location: 'frontend',
//       data
//     }
//     const timeNow = Date.now()
//     const delta = timeNow - logsSentAt
//     // jsLogger.log('LOG: delta', delta, 'logs in buffer', logsBuffer.length)
//     if (delta > 10000 || flush) {
//       const logs = [...logsBuffer, l]
//       logsBuffer = []
//       logsSentAt = Date.now()
//       jsLogger.log('Senging LOGs: amount', logs.length)
//       const req = await fetch(url, {
//         method: 'POST',
//         body: JSON.stringify({ logs }),
//         headers
//       })
//       if (req.ok) {
//         // const j = await req.json()
//         jsLogger.log('Senging LOGs - OK')
//       } else {
//         jsLogger.log('Senging LOGs, status', req.status, req.statusText)
//       }
//     } else {
//       logsBuffer.push(l)
//     }
//     return null
//   } catch (e) {
//     jsLogger.warn('send log error', get(e, 'mssage'))
//   }
// }

export const flushLogs = async (interactionId: string) => {
  // const url = `${process.env.REACT_APP_API_URL}/emailCode/${interactionId}/flushLogs`
  // const req = await fetch(url, {
  //   method: 'GET'
  // })
  // const data = await req.json()
  // jsLogger.log('flushLogs response', data)
  // return data
}

export const checkIsWebMSupported = () => {
  // Create a dummy video element
  var video = document.createElement('video')

  // Check if the browser supports the WebM video format
  return video.canPlayType('video/webm') !== ''
}

export const validateEmail = async (email: string) => {
  const source = 'code'
  const url = `${process.env.REACT_APP_PUBLIC_ENDPOINT}/validateEmail`
  const req = await fetch(url, {
    method: 'POST',
    body: JSON.stringify({ email, source })
  })
  const data = await req.json()
  jsLogger.log('validateEmail response', data)
  return data
}
