import { FC } from 'react'
import { ReactComponent as IconCheck } from 'shared/assets/faCheck.svg'

type Props = {
  isChecked: boolean
  onChange: (v: boolean) => void
  isError: boolean
}

const ConfirmationCheckbox: FC<Props> = ({ onChange, isChecked, isError }) => {
  const textColor = isError ? 'text-red-500' : 'text-gray-400'
  return (
    <div
      className='flex flex-row w-full pt-[30px] px-4 items-center cursor-pointer'
      onClick={() => onChange(!isChecked)}
    >
      <div className='w-5 h-5 rounded-[4px] border border-gray-500 shrink-0 flex items-center justify-center text-teal-500'>
        {isChecked && <IconCheck />}
      </div>
      <div className='pl-2 text-xs'>
        <span className={textColor}>
          Yes, I would like to hear more about FaceSign
        </span>
      </div>
    </div>
  )
}

export default ConfirmationCheckbox
