import { ReactNode, useState } from 'react'
import Typewriter from 'typewriter-effect'
import map from 'lodash/map'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp'

import { ReactComponent as ChatIcon } from 'shared/assets/chat-icon.svg'
import { InteractionT } from 'shared/types/facesign'


interface Props {
  overallSummary?: string,
  analysis?: InteractionT.AIAnalysisSection[]
  steps?: InteractionT.StepT[]
}

const SectionWrapper = ({ 
  label,
  children,
  type,
  description
}: {
  label: string,
  children?: ReactNode,
  type?: 'transcript' | 'analysis'
  description?: string | { text: string, type: InteractionT.StepTypeT }[]
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const renderDescription = () => {
    if (typeof description === 'string') {
      return (
        <p className='text-white text-sm whitespace-pre-wrap'>
          {description}
        </p>
      )
    } else if (description) {
      return (
        <div>
          {description.map((v, i) => (
            <p key={i} className={`text-sm whitespace-pre-wrap ${i % 2 === 0 ? 'text-blue-400' : 'text-white'}`}>
              {v.type === 'question' ? 'FACESIGN' : 'USER'}: {v.text}
            </p>
          ))}
        </div>
      )
    }
  }

  return (
    <div className='flex flex-row w-full'>
      <div className='mt-1'>
        <ChatIcon width='24px' height='24px' />
      </div>
      <div className='flex flex-col gap-4 ml-2 w-full'>
        <p className='font-medium text-md text-black'>
          {label}
        </p>
        {children}
        {description && (
          <div className='flex flex-col items-start justify-center gap-4 w-full'>
            {/* <button
              className='flex flex-row items-center justify-center gap-2'
              onClick={() => setIsOpen(!isOpen)}
            >
              <p className='text-gray-600 text-sm'>
                {isOpen ? `Hide ${type}` : `Show ${type}`}
              </p>
              <div>
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
              </div>
            </button>
            {isOpen && ( */}
              <div className='flex flex-col gap-4 rounded-xl bg-black p-4 w-full'>
                <p className='uppercase text-gray-400 text-xs'>
                  {type === 'analysis' ? 'Detailed analysis' : 'Transcript'}
                </p>
                {renderDescription()}
              </div>
            {/* )} */}
          </div>
        )}
      </div>
    </div>
  )
}

const AnalysisContent = ({
  overallSummary,
  analysis,
  steps
}: Props) => {
  const [writingDone, setWritingDone] = useState<boolean>(false)
  return (
    <div className='my-8 h-fit-content flex flex-col'>
      {overallSummary && (
        <SectionWrapper label='AI Analysis'>
          <Typewriter
            options={{
              cursor: '●'
            }}
            onInit={(typewriter) => {
              typewriter
                .callFunction((state) => {
                  state.elements.cursor.style.color = '#16C95D';
                })
                .changeDelay(25)
                .typeString(overallSummary)
                .start()
                .callFunction((state) => {
                  setWritingDone(true)
                  state.elements.cursor.style.display = 'none'
                })
            }}
          />
        </SectionWrapper>
      )}
      <div className={`flex-col gap-6 mt-6 ${writingDone ? 'flex' : 'hidden'}`}>
        {map(analysis, (v, i) => {
          return (
            <SectionWrapper
              key={i}
              label={v.title}
              type='analysis'
              description={v.longDescription}
            >
              <p className='text-md text-gray-700'>
                {v.shortDescription}
              </p>
            </SectionWrapper>
          )
        })}
        <SectionWrapper
          label='Transcript'
          type='transcript'
          description={steps?.map(v => ({ text: get(v, 'text', '') as string, type: v.sType }))}
        />
      </div>
    </div>
  )
}

export default AnalysisContent
