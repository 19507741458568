import { FC, useEffect, useRef, useState } from 'react'
import jsLogger from 'js-logger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'

import { getRecapInfo } from 'controllers/main'
import DataContent from 'pages/recap/DataContent'
import RecapVideos from 'pages/recap/RecapVideos'
import PageContainer from 'shared/components/PageContainer'
import Loading from 'shared/components/recap/Loading'
import { InteractionT } from 'shared/types/facesign'
import { checkIsWebMSupported } from 'controllers/main'
import PACKAGE from '../../package.json'
import { getBackClient } from 'controllers/back'
import { ReactComponent as Icon } from 'shared/assets/facesign-ai-icon.svg'
import AnalysisContent from 'pages/recap/AnalysisContent'

type Props = {
  interactionId: string
  chunks: { user: Blob[]; avatar: Blob[] }
  recapData: InteractionT.RecapT | null
  isTest: boolean
}

const Recap: FC<Props> = ({ interactionId, chunks, recapData, isTest }) => {
  const [data, setData] = useState<InteractionT.RecapT | null>(recapData)
  // const [chunksData, setChunksData] =
  //   useState<Partial<InteractionT.RecapT> | null>(null)
  const [videoOpen, setVideoOpen] = useState<boolean>(false)
  const updateTimerRef = useRef<number>()
  const requestsAmountRef = useRef(0)
  const isWebmSupported = useRef(false)

  useEffect(() => {
    if (isTest && data && data?.aiAnalysis) {
      // @ts-ignore
      if (window.fireTestEvent) {
        // @ts-ignore
        window.fireTestEvent('ev_ai_analysis_ready', {
          interactionId,
          version: PACKAGE.version
        })
      }
    }
  }, [isTest, data?.aiAnalysis])

  useEffect(() => {
    isWebmSupported.current = checkIsWebMSupported()
  }, [interactionId, data])

  useEffect(() => {
    jsLogger.log('RECAP page', { interactionId })
    const back = getBackClient()
    back.disconnect()
    const fetchData = async () => {
      const res = await getRecapInfo(interactionId)
      requestsAmountRef.current = requestsAmountRef.current + 1
      if (
        requestsAmountRef.current > 10 ||
        (res && res.avatarVideoUrl && res.userVideoUrl)
      ) {
        clearInterval(updateTimerRef.current)
      }
      setData(res)
    }

    updateTimerRef.current = window.setInterval(fetchData, 5000)
    // if (chunks.user.length > 0 || chunks.avatar.length > 0) {
    //   const userVideoBlob = new Blob(chunks.user)
    //   const userVideoUrl = URL.createObjectURL(userVideoBlob)
    //   const avatarVideoBlob = new Blob(chunks.avatar)
    //   const avatarVideoUrl = URL.createObjectURL(avatarVideoBlob)
    //   setChunksData({
    //     userVideoUrl,
    //     avatarVideoUrl,
    //     userVideoMimeType: chunks.user[0].type,
    //     avatarVideoMimeType: chunks.avatar[0].type
    //   })
    // }
    fetchData()

    return () => clearInterval(updateTimerRef.current)
  }, [interactionId, chunks])

  // const renderVideos = () => {
  //   return (
  //     <div className='rounded-xl mt-4'>
  //       <RecapVideos
  //         userVideoUrl={data?.userVideoUrl ?? chunksData?.userVideoUrl}
  //         userVideoMimeType={
  //           data?.userVideoMimeType ?? chunksData?.userVideoMimeType
  //         }
  //         avatarVideoUrl={data?.avatarVideoUrl ?? chunksData?.avatarVideoUrl}
  //         avatarVideoMimeType={
  //           data?.avatarVideoMimeType ?? chunksData?.avatarVideoMimeType
  //         }
  //         userAvatarUrl={data?.userAvatarUrl}
  //         isWebmSupported={isWebmSupported.current}
  //       />
  //     </div>
  //   )
  // }

  const renderInfo = () => {
    if (data) {
      return (
        <div className='flex flex-col flex-1 overflow-y-scroll no-scrollbar'>
          <div className='flex flex-col pt-14 px-4 pb-6 portrait:bg-gray-50'>
            <div className='flex flex-row items-center justify-between mb-4'>
              <div className='flex flex-row items-center justify-start gap-2'>
                <Icon />
                <p className='font-medium text-xl text-gray-800'>
                  {videoOpen ? 'Session Video Playback' : 'Session summary'}
                </p>
              </div>
              {/* {videoOpen && (
                <button onClick={() => setVideoOpen(false)}>
                  <FontAwesomeIcon icon={faTimes} size='xl' />
                </button>
              )} */}
            </div>
            {
              // videoOpen
              //   ? renderVideos()
              //   :
              data && (
                <DataContent
                  recap={data}
                  // videoOpen={videoOpen}
                  // setVideoOpen={setVideoOpen}
                />
              )
            }
          </div>
          <div className='px-4'>
            <AnalysisContent
              overallSummary={data.aiAnalysis?.overallSummary}
              analysis={data.aiAnalysis?.analysis}
              steps={data.steps}
            />
          </div>
        </div>
      )
    }
  }

  if (!data) {
    return (
      <PageContainer version={PACKAGE.version}>
        <Loading waitingForUpload={false} />
      </PageContainer>
    )
  } else {
    return (
      <PageContainer version={PACKAGE.version}>{renderInfo()}</PageContainer>
    )
  }
}

export default Recap
