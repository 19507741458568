import { FC, ReactNode, useState } from 'react'
import get from 'lodash/get'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMap } from '@fortawesome/pro-light-svg-icons/faMap'
import { faWavePulse } from '@fortawesome/pro-light-svg-icons/faWavePulse'
import { faUserHair } from '@fortawesome/pro-light-svg-icons/faUserHair'
import { faLaptopMobile } from '@fortawesome/pro-light-svg-icons/faLaptopMobile'
import { faWaveformLines } from '@fortawesome/pro-light-svg-icons/faWaveformLines'

import { InteractionT } from 'shared/types/facesign'
import { ReactComponent as IconCheck } from 'shared/assets/iconCheckCircle.svg'
import { ReactComponent as PlayButton } from 'shared/assets/play-button.svg'

type Props = {
  recap: InteractionT.RecapT
  videoOpen?: boolean
  setVideoOpen?: (v: boolean) => void
}

type RowT = {
  title: string
  desc?: string
  children?: ReactNode
  icon?: ReactNode
}

const ICON_COLOR = '#718096'

const DataContentRow: FC<RowT> = ({ title, desc, children, icon }) => {
  return (
    <div className='w-full flex flex-row items-center gap-1'>
      <div className='flex flex-grow-0 flex-shrink-0 w-5 items-center justify-center'>
        {icon ?? (
          <div className='text-green-500'>
            <IconCheck />
          </div>
        )}
      </div>
      <div className='flex flex-col pl-3'>
        <span className='text-sm text-gray-700 font-bold'>
          {title}
        </span>
        {desc && (
          <span className='text-gray-700 text-sm whitespace-nowrap font-normal'>
            {desc}
          </span>
        )}
        <span className='w-full pt-[5px] text-xs text-gray-700'>
          {children}
        </span>
      </div>
    </div>
  )
}

const DataContent: FC<Props> = ({ 
  recap
  // setVideoOpen
}) => {
  const [imageLoaded, setImageLoaded] = useState<boolean>(false)
  const renderLocation = () => {
    if (recap.ipDetails) {
      const loc = `${recap.ipDetails.city}, ${recap.ipDetails.country}`
      return (
        <DataContentRow
          title='Location:'
          icon={<FontAwesomeIcon icon={faMap} color={ICON_COLOR} />}
        >
          <span>{loc}</span>
        </DataContentRow>
      )
    }
  }

  const renderDeviceInfo = () => {
    if (recap.deviceDetails) {
      const loc = `${recap.deviceDetails.platform}, ${recap.deviceDetails.browser} browser`
      return (
        <DataContentRow
          title='Device:'
          icon={
            <FontAwesomeIcon icon={faLaptopMobile} color={ICON_COLOR} />
          }
        >
          <span>{loc}</span>
        </DataContentRow>
      )
    }
  }

  const renderFacialAnalysis = () => {
    if (recap.imageRekognition && recap.imageRekognition.length > 0) {
      const face = get(recap.imageRekognition, [0, 'faces', 0])
      const ar = []
      const ageMin = get(face, 'AgeRange.Low')
      const ageHigh = get(face, 'AgeRange.High')
      const age = `${ageMin} - ${ageHigh} year old`
      ar.push(age)
      const gender = get(face, 'Gender.Value', '')
      ar.push(
        get(face, 'Sunglasses.Value', false) ? 'wearing sunglasses' : null
      )
      ar.push(gender.toLowerCase())
      ar.push(get(face, 'Eyeglasses.Value', false) ? 'wearing glasses' : null)
      ar.push(get(face, 'Beard.Value', false) ? 'has a beard' : null)
      ar.push(get(face, 'Mustache.Value', false) ? 'has a mustache' : null)
      // const confidence = round(get(face, 'Confidence', 0), 3)
      return (
        <DataContentRow
          title='Facial analysis:'
          // desc={`Confidence ${confidence}%`}
          icon={
            <FontAwesomeIcon icon={faUserHair} color={ICON_COLOR} />
          }
        >
          <span>{ar.filter(v => v).join(', ')}</span>
        </DataContentRow>
      )
    }
  }

  // const renderMoodAnalysis = () => {
  //   if (recap.imageRekognition && recap.imageRekognition.length > 0) {
  //     const ar: string[] = []
  //     recap.imageRekognition.forEach(r => {
  //       const face = get(r, ['faces', 0])
  //       if (face && face.Emotions && face.Emotions.length > 0) {
  //         face.Emotions.forEach(e => {
  //           if (e.Confidence && e.Confidence > 90 && e.Type) {
  //             ar.push(e.Type.toLowerCase())
  //           }
  //         })
  //       }
  //     })
  //     if (ar.length > 0) {
  //       return (
  //         <DataContentRow
  //           title='Mood analysis:'
  //           desc={`${recap.imageRekognition.length} mood events detected`}
  //         >
  //           <span>
  //             You were <b>{uniq(ar).join(', ')}</b> detected at various points
  //             in the conversation.
  //           </span>
  //         </DataContentRow>
  //       )
  //     }
  //   }
  // }

  // const renderCoercion = () => {
  //   return (
  //     <DataContentRow title='Coercion detection:'>
  //       <span>No signs of coercion or stress detected.</span>
  //     </DataContentRow>
  //   )
  // }

  const renderLiveness = () => {
    return (
      <DataContentRow
        title='Liveness detection:'
        // desc='Confidence 97%'
        icon={
          <FontAwesomeIcon icon={faWavePulse} color={ICON_COLOR} />
        }
      >
        <span>
          Live person, not a deepfake
        </span>
      </DataContentRow>
    )
  }

  const renderVoiceAnalysis = () => {
    if (recap.imageRekognition && recap.imageRekognition.length > 0) {
      const face = get(recap.imageRekognition, [0, 'faces', 0])
      const gender = get(face, 'Gender.Value', '')
      return (
        <DataContentRow
          title='Voice analysis:'
          icon={
            <FontAwesomeIcon icon={faWaveformLines} color={ICON_COLOR} />
          }
        >
          <span>{gender} voice; native English (en-US)</span>
        </DataContentRow>
      )
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row pt-6 justify-between gap-1'>
        <div className='flex flex-col gap-5'>
          {renderFacialAnalysis()}
          {renderLiveness()}
          {renderLocation()}
          {renderVoiceAnalysis()}
          {renderDeviceInfo()}
          {/* {renderMoodAnalysis()}
          {renderCoercion()} */}
        </div>
        <div 
          className='relative h-fit-content flex flex-col flex-shrink-0' 
          // onClick={() => setVideoOpen && setVideoOpen(true)}
        >
          <img
            src={get(recap, ['userScreenshots', get(recap, 'aiAnalysis.bestPhotoIndex', 0)], get(recap, 'userAvatarUrl', ''))}
            alt=''
            className='scale-x-[-1] portrait:w-28 landscape:w-40 rounded ml-1'
            onLoad={() => setImageLoaded(true)}
          />
          {/* {imageLoaded && recap.avatarVideoUrl && recap.userVideoUrl && (
            <div className='absolute w-full h-full top-0 left-0 flex items-center justify-center'>
              <PlayButton />
            </div>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default DataContent
