import { FC, useMemo, useState } from 'react'
import PageContainer from 'shared/components/PageContainer'
import RecorderControls from 'shared/components/recorder/RecorderControls'
import { ReactComponent as IconLeft } from 'shared/assets/iconLeft.svg'
import Bowser from 'bowser'
import PSafariMobile from 'shared/components/permissionsHandler/PSafariMobile'
import PChromeMobile from 'shared/components/permissionsHandler/PChromeMobile'
import jsLogger from 'js-logger'

type Props = {
  permissionsError: string | null
  verificationId: string
}

const PermissionsHandler: FC<Props> = ({ verificationId }) => {
  const [secondTime] = useState(
    () => localStorage.getItem(`${verificationId}_permissions`) !== null
  )

  const b = useMemo(() => {
    return Bowser.parse(window.navigator.userAgent)
  }, [])

  const onRetry = () => {
    jsLogger.log('Permissions page: click on Retry')
    localStorage.setItem(`${verificationId}_permissions`, 'true')
    window.location.reload()
  }

  // const showSafariMobile = true
  console.log('b', b)

  if (secondTime && b.platform.type === 'mobile' && b.os.name === 'iOS') {
    // if (secondTime) {
    if (b.browser.name === 'Safari') {
      return <PSafariMobile onRetry={onRetry} />
    } else if (b.browser.name === 'Chrome') {
      return <PChromeMobile onRetry={onRetry} />
    }
  } else {
    return (
      <PageContainer>
        <div className='w-full h-full relative bg-blackAlpha-800 flex items-center justify-center'>
          <div
            className={
              'flex-1 bg-slate-100 rounded-md absolute top-[3rem] left-5 w-28 h-44 '
            }
          >
            <div
              className={
                'absolute top-0 left-0 bottom-0 right-0 w-full h-full rounded-md border'
              }
            >
              <div key='camera' className='w-full h-full rounded-md'>
                <RecorderControls
                  controls={{ camera: false, mic: false }}
                  handleMicOrCameraClick={() => null}
                />
              </div>
            </div>
          </div>
          <div className='flex items-center justify-center flex-col '>
            {/* <span className='text-whiteAlpha-900 font-semibold font-[10px] '>
            {JSON.stringify(b)}
          </span> */}
            <span className='text-whiteAlpha-900 font-semibold text-[19px] '>
              <i>Camera or Microphone blocked</i>
            </span>
            <div
              className='w-[315px] h-[60px] bg-teal-500 mt-6 flex flex-row items-center justify-center text-whiteAlpha-900 hover:cursor-pointer'
              onClick={onRetry}
            >
              <IconLeft />
              <span className='pl-2 text-[20px] font-semibold '>Retry</span>
            </div>
          </div>
        </div>
      </PageContainer>
    )
  }
}

export default PermissionsHandler
