import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import get from 'lodash/get'

import '@fontsource/inter'
// import '@fontsource/inter/100.css'
// import '@fontsource/inter/200.css'
// import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/800.css'
import '@fontsource/inter/900.css'
import adapter from 'webrtc-adapter'

import * as Sentry from '@sentry/react'
import PACKAGE from '../package.json'
import { sendError } from 'controllers/main'
import jsLogger from 'js-logger'
import { getBackClient } from 'controllers/back'
import { ErrorBoundary } from 'react-error-boundary'

jsLogger.useDefaults()
jsLogger.setLevel(jsLogger.DEBUG)

const backClient = getBackClient()

const consoleHandler = jsLogger.createDefaultHandler({
  formatter: function (messages, context) {
    // prefix each log message with a timestamp.
    messages.unshift(`${context.level.name}:`)
  }
})

jsLogger.setHandler(function (messages, context) {
  // Send messages to a custom logging endpoint for analysis.
  // TODO: Add some security? (nah, you worry too much! :P)
  // console.log('----- lllogg', messages, context)
  consoleHandler(messages, context)
  backClient.sendLog(messages, context)
})

Sentry.init({
  dsn: 'https://b4dd0bf13deac723115a54a5a36b2b95@o4504129999667200.ingest.sentry.io/4506631814053888',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: [
      //   'localhost',
      //   'https://api.facefile.co',
      //   'https://code.facesign.co'
      // ]
    })
    // new Sentry.Reply({
    //   maskAllText: false,
    //   blockAllMedia: false
    // })
  ],
  // Performance Monitoring
  // tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Reply
  // replysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: PACKAGE.version,
  environment:
    window.location.hostname === 'localhost' ? 'localhost' : 'production',
  beforeSend: async (event: Sentry.Event, hint: Sentry.EventHint) => {
    // console.log('BEFORE SEND event:', event)
    // console.log('BEFORE SEND hint:', hint)
    await sendError(event, hint)
    return event
  }
})

// @ts-ignore
window.processTestEvent = (
  kind: string,
  timeout?: number,
  handlePastEvents?: boolean
) => {
  console.log('PROCESS TEST EVENT', kind, timeout)
  return new Promise(function (resolve, reject) {
    if (timeout) {
      setTimeout(function () {
        reject(
          new Error(`event ${kind} is not fired after ${timeout / 1000} sec`)
        )
      }, timeout)
    }
    var elt = document.getElementById('event_div')
    if (elt && elt.classList.contains(kind) && handlePastEvents) {
      resolve(true)
    } else if (elt) {
      elt.addEventListener(kind, function (ev) {
        const evDetail = get(ev, 'detail')
        console.log('TEST EVENT CATCHED', evDetail)
        resolve(evDetail)
      })
    } else {
      reject(new Error('event_div is not found'))
    }
  })
}

// @ts-ignore
window.fireTestEvent = (kind: string, data: any) => {
  console.log('FIRE TEST EVENT', kind, data)
  const elt = document.getElementById('event_div')
  // console.log('element found', elt)
  if (elt) {
    elt.dispatchEvent(
      new CustomEvent(kind, {
        detail: data
      })
    )
    elt.classList.add(kind)
  }
}

// @ts-ignore
window.fireTestReplyEvent = (reply: string) => {
  console.log('FIRE TEST REPLY EVENT', reply)
  const elt = document.getElementById('event_reply_div')
  // console.log('element found', elt)
  if (elt) {
    elt.dispatchEvent(
      new CustomEvent('ev_reply', {
        detail: reply
      })
    )
  }
}

// console.log('process.env', process.env)
console.log('browser:', adapter.browserDetails)

const logError = (error: Error, info: any) => {
  jsLogger.error('Unexpected error occurried', {
    error,
    info,
    componentStack: get(info, 'componentStack')
  })
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary fallback={<div>Something went wrong</div>} onError={logError}>
    <App />
  </ErrorBoundary>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
